<!-- 我的 -->
<template>
  <div class="container my">
    <!-- 顶部用户信息 -->
    <div class="topBox">
      <!-- <img src="@/assets/images/home/top.png" class="bg" /> -->
      <div class="userInfo">
        <div class="header_left">
          <div class="avatarWrap">
            <van-image
              class="header_img"
              round
              width="10rem"
              height="10rem"
              :src="userInfo.avatar"
            />
          </div>
          <div class="nicknameWrap">
            <div class="nickname">
              <span>{{ userInfo.nickname }}</span>
            </div>
            <div class="tel">{{ userInfo.telephone }}</div>
            <div class="joinDays" v-if="userInfo.joinDays">
              这是您加入学习的第<span>{{ userInfo.joinDays }}天</span>
            </div>
          </div>
        </div>
        <div class="header_right" @click="toMyInfo">
          <img src="@/assets/images/my/edit_icon.png" alt="" />
        </div>
      </div>
    </div>
    <div class="inner_box">
      <div class="my_classes" @click="toItem('myCourses')">
        <div class="my_class_left">
          <img
            class="class_icon"
            src="@/assets/images/my/class_icon.png"
            alt=""
          />
          我的课程
        </div>
        <img
          class="right_arrow"
          src="@/assets/images/my/arrow_icon.png"
          alt=""
        />
      </div>
      <div class="my_classes" @click="toItem('myOrder')">
        <div class="my_class_left">
          <img
            class="class_icon"
            src="@/assets/images/my/order_icon.png"
            alt=""
          />
          我的订单
        </div>
        <img
          class="right_arrow"
          src="@/assets/images/my/arrow_icon.png"
          alt=""
        />
      </div>
      <div class="my_classes" @click="toService">
        <div class="my_class_left">
          <img
            class="class_icon"
            src="@/assets/images/my/servier_icon.png"
            alt=""
          />
          联系客服
        </div>
        <img
          class="right_arrow"
          src="@/assets/images/my/arrow_icon.png"
          alt=""
        />
      </div>
      <div class="position_a">
        <div class="logout" @click="logout">退出登录</div>
      </div>
    </div>
    <router-view class=""></router-view>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { getNotice } from "@/api/notice";
import { getMyInfo } from "@/api/login";
import { forcePay, facePay } from "@/api/pay";
import { setStore, getStore } from "@/utils/mUtils";
import { removeStore } from "../../utils/mUtils";
import { Toast } from "vant";

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      userInfo: {},
      topBar: require("@/assets/images/home/top.png"),
      beforeExam: {},
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    toService() {
      window.location.href =
        "https://work.weixin.qq.com/kfid/kfcb91156dc7f8e5af4";
    },
    toMyInfo() {
      this.$router.push({
        path: "/my/info",
      });
    },
    toItem(path, id) {
      this.$router.push({
        path: `/my/${path}`,
        query: { id: id },
      });
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    async getMyInfoFunc() {
      const res = await getMyInfo();
      this.userInfo = res.data;
      setStore("userInfo", res.data);
    },
    logout() {
      Toast("已退出");
      localStorage.clear();
      // setInterval(() => {
      this.$router.push({
        path: "/home",
      });
      // }, 2000);
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.initWXJSSDKNoShare()
    // 获取我的信息
    this.getMyInfoFunc();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$EventBus.$on("getMyInfoFunc", this.getMyInfoFunc);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {
    this.$EventBus.$off("getMyInfoFunc", this.getMyInfoFunc);
  }, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variable.scss";
.container.my {
  background-color: #1d96f4;
  height: 100%;
  display: block;
  // .my_header {
  //   width: 100%;
  //   height: 200px;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 0 30px;
  //   background: #333;
  // .header_left {
  //   display: flex;
  // }
  .topBox {
    position: relative;
    z-index: 3;
  }

  .bg {
    width: 100vw;
    height: 267px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .userInfo {
    display: flex;
    align-items: center;
    position: absolute;
    left: 30px;
    top: 40px;
    width: calc(100vw - 60px);
    justify-content: space-between;
  }

  .avatarWrap {
    width: 124px;
    height: 124px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header_img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50%;
    object-fit: cover;
  }

  .nicknameWrap {
    display: flex;
    flex-direction: column;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    line-height: 42px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 20px;
  }

  .joinDays {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    opacity: 0.7;
  }

  .nickname {
    width: 480px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    line-height: 42px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tel {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 4px 0;
    opacity: 0.7;
  }
  .header_left {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .header_right {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  // }
  .inner_box {
    position: relative;
    padding: 30px;
    background: #f7f7f7;
    border-radius: 30px 30px 0px 0px;
    width: 100%;
    height: calc(100vh - 200px);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 9;
    .my_classes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px;
      width: 100%;
      // height: 108px;
      background: #ffffff;
      border-radius: 20px;
      margin-bottom: 20px;
      .my_class_left {
        display: flex;
        align-items: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #333333;
        line-height: 36px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        .class_icon {
          width: 50px;
          height: 50px;
          margin-right: 28px;
        }
      }
      .right_arrow {
        width: 12px;
        height: 20px;
      }
    }
    .position_a {
      width: 690px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      position: absolute;
      bottom: 270px;
      padding: 30px;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #333333;
      line-height: 36px;
      font-style: normal;
      text-transform: none;
    }
  }
}
</style>
